import { Box, Typography } from "@mui/material";
import { useState } from "react";

export default function PhotoViewer() {
  let menu = {
    fontSize: "1.25vw",
    fontFamily: "W95FA",
  };

  let obj = [
    {
      name: "Fighter Card 1.png",
      src: "/images/NEW_GIRL_3.png",
      alt: "fighter card 1",
    },
    {
      name: "Fighter Card 2.png",
      src: "/images/GIRL_2.png",
      alt: "fighter card 2",
    },
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (src) => {
    setSelectedImage(src);
  };

  return (
    <Box position="relative" height="100vh">
      <Box
        bgcolor="#C0C0C0"
        width={"100%"}
        height={"5%"}
        display={"flex"}
        gap={"1vw"}
        padding={"0 .25em"}
        zIndex={"-1"}
        position={"relative"}
      >
        <Typography sx={menu} variant={"p"}>
          File
        </Typography>
        <Typography sx={menu} variant={"p"}>
          View
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Play
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Favorites
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Go
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Help
        </Typography>
      </Box>
      <Box
        component="img"
        src="/images/photoViewer.png"
        alt="photo viewer"
        width={"100%"}
        height={"100%"}
      ></Box>
      <Box
        position={"absolute"}
        width={"20%"}
        top={"13%"}
        left={"25%"}
        height={"20%"}
        padding={"0 .25em"}
      >
        {obj.map((item, index) => (
          <Typography
            key={index}
            onClick={() => handleImageClick(item.src)}
            sx={{
              ...menu,
              cursor: "pointer",
              backgroundColor:
                selectedImage === item.src ? "blue" : "transparent",
              color: selectedImage === item.src ? "white" : "black",
              padding: selectedImage === item.src ? "0.25em" : "0",
            }}
          >
            {item.name}
          </Typography>
        ))}
      </Box>
      <Box
        position={"absolute"}
        width={"54%"}
        height={"62%"}
        top={"12.25%"}
        left={"46%"}
      >
        <Box
          component="img"
          src={selectedImage}
          //   alt="selected"
          height={"100%"}
          width={"100%"}
        ></Box>
      </Box>
    </Box>
  );
}
