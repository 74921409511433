import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

export default function MediaPlayer() {
  let menu = {
    fontSize: "1.25vw",
    fontFamily: "W95FA",
  };

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById("mediaPlayerVideo");

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);

    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
    };
  }, []);

  return (
    <Box position={"relative"} height={"100%"}>
      <Box
        bgcolor="#C0C0C0"
        width={"100%"}
        height={"100%"}
        display={"flex"}
        gap={"1vw"}
        padding={"0 .25em"}
      >
        <Typography sx={menu} variant={"p"}>
          File
        </Typography>
        <Typography sx={menu} variant={"p"}>
          View
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Play
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Favorites
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Go
        </Typography>
        <Typography sx={menu} variant={"p"}>
          Help
        </Typography>
      </Box>
      <Box
        bgcolor={"black"}
        width={"100%"}
        height={"100%"}
        position={"absolute"}
        top={"3vh"}
      >
        {!isPlaying && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bgcolor="rgba(0, 0, 0, 0.5)"
            zIndex={1}
            onClick={() => document.getElementById("mediaPlayerVideo").play()}
          />
        )}
        <video
          id="mediaPlayerVideo"
          src="/video/WMW_VIDEO.mp4"
          width="100%"
          height="100%"
          controls
          style={{ objectFit: "cover", position: "relative", zIndex: 0 }}
        ></video>
      </Box>
    </Box>
  );
}
