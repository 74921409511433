import { Box, Typography } from "@mui/material";

export default function RecycleBin() {
  return (
    <Box
      bgcolor="white"
      display={"flex"}
      justifyContent={"center"}
      height={"100%"}
    >
      <Box
        component="img"
        src="/images/trashcoon.jpg"
        alt="you"
        width={"100%"}
      ></Box>
    </Box>
  );
}
