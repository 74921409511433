import "./App.css";
import Desktop from "./components/Desktop/Desktop";
import { Box } from "@mui/material";

function App() {
  return (
    <Box position={"relative"}>
      <Desktop />
    </Box>
  );
}

export default App;
