import { Box, Typography } from "@mui/material";
import "./browser.css";

let ads = {
  display: "flex",
  flexDirection: "column",
  width: "15vw",
  fontSize: "1.25vw",
  overflow: "hidden",
};

let adInput = {
  width: "1.5vw",
};

const pixel = {
  fontFamily: "W95FA",
};

let obj = [
  [
    { name: "Live Mud Wrestling", src: "/images/ads/AD_1.png", id: 0 },
    { name: "Like Asian Women", src: "/images/ads/0.jpg", id: 1 },
    { name: "Live Hot Singles", src: "/images/ads/AD_3.png", id: 2 },
    { name: "Local Singles", src: "/images/ads/2.png", id: 1 },
    { name: "Women In Your Area", src: "/images/ads/9.jpg", id: 0 },
    { name: "Hot Sexy Singles", src: "/images/ads/4.jpg", id: 0 },
    { name: "Women In Your Area", src: "/images/ads/6.jpg", id: 0 },
    { name: "Local Singles", src: "/images/ads/2.png", id: 1 },
    { name: "Local Singles", src: "/images/ads/2.png", id: 1 },
    { name: "Local Singles", src: "/images/ads/AD_5.png", id: 1 },
  ],
  [
    { name: "Real Live Mud Wrestling", src: "/images/ads/AD_2.png", id: 2 },
    { name: "Date Hot Russians", src: "/images/ads/7.jpg", id: 1 },
    { name: "Hot, Muddy, Sexy", src: "/images/ads/AD_4.png", id: 2 },
    { name: "Dermatologists", src: "/images/ads/3.jpg", id: 1 },
    { name: "Christian Singles", src: "/images/ads/5.jpeg", id: 1 },
    { name: "Looking For Love", src: "/images/ads/9.jpg", id: 1 },
    { name: "No CC Required", src: "/images/ads/10.png", id: 1 },
    { name: "Live Mud Wrestling", src: "/images/ads/AD_1.png", id: 0 },
    { name: "Thick MILFS", src: "/images/ads/AD_6.png", id: 0 },
    { name: "Hot, Muddy, Sexy", src: "/images/ads/AD_4.png", id: 2 },
  ],
];

export default function Browser() {
  return (
    <Box display="flex" flexDirection={"column"} position={"relative"}>
      <Box component="img" src="/images/newSearch.png" alt="search" />
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        position={"relative"}
        bgcolor={"white"}
      >
        <Ads side="left" onClick={() => {}} />
        <MiddleContent />
        <Ads side="right" />
      </Box>
    </Box>
  );
}

function MiddleContent() {
  return (
    <Box bgcolor={"white"} width={"55%"} className="adName">
      <Box
        display={"flex"}
        justifyContent="space-between"
        bgcolor="yellow"
        // position={"sticky"}
        bottom={"8.5%"}
        zIndex={"10"}
      >
        <Typography
          component="a"
          href="https://www.twitch.tv/wmwrestling"
          variant="h4"
          fontWeight={"600"}
        >
          Twitch
        </Typography>
        <Typography
          component="a"
          href="https://kick.com/womenmudwrestling"
          variant="h4"
          fontWeight={"600"}
        >
          Kick
        </Typography>
        <Typography
          component="a"
          href="https://x.com/WMWonsol
"
          variant="h4"
          fontWeight={"600"}
        >
          Twitter
        </Typography>
        <Typography
          component="a"
          href="https://t.me/WomenMudWrestling"
          variant="h4"
          fontWeight={"600"}
        >
          Telegram
        </Typography>
        <Typography
          component="a"
          href="https://dexscreener.com/solana/9KvTNxNeBeAtHke6XWgA6hBDfzo4Lk8Ase58UXdtKTKc"
          variant="h4"
          fontWeight={"600"}
        >
          Dex
        </Typography>
      </Box>

      <Box
        padding={"3em 0"}
        display={"flex"}
        flexDirection={"column"}
        gap="1em"
      >
        <Typography component="a" href="https://x.com/WMWonsol">
          <Typography variant="h1" textAlign="center" fontSize={"4vw"}>
            WOMENS MUD WRESTLING
          </Typography>
        </Typography>

        <Typography variant="h3" textAlign={"center"} fontSize={"2.5vw"}>
          on Solana
        </Typography>
      </Box>

      <Box paddingTop={"1em"}>
        <Typography
          textAlign={"center"}
          variant="h3"
          fontWeight={"600"}
          fontSize={"3vw"}
        >
          What is Mud Wrestling?
        </Typography>
        <Box>
          <Box component="img" src="/images/hero.jpg" width={"100%"}></Box>
          <Typography
            padding={"1em"}
            gap={"1em"}
            variant="h5"
            fontSize={"1.5vw"}
          >
            Mud wrestling is defined as physical confrontation (fighting,
            wrestling, etc.) that occurs in mud (or mud-like substances) or a
            mud pit. The popular modern interpretation specifies that
            participants wrestle while wearing minimal clothing and usually
            going barefoot, with the usual emphasis on presenting an
            entertaining spectacle as opposed to physically injuring or
            debilitating the opponent to the point where they are unable to
            continue the match
          </Typography>
        </Box>
      </Box>

      <Box paddingTop={"1em"}>
        <Typography
          textAlign={"center"}
          variant="h3"
          fontWeight={"600"}
          fontSize={"3vw"}
        >
          Timeline
        </Typography>
        <Box
          component="img"
          src="/images/timeline.png"
          alt="timeline"
          width={"100%"}
        ></Box>
      </Box>

      <Box paddingTop={"1em"}>
        <Typography component="a" href="https://www.twitch.tv/wmwrestling">
          <Typography
            variant="h2"
            textAlign="center"
            padding={".5em 0"}
            fontSize={"4vw"}
          >
            Watch Women Mud Wrestling Live Now!
          </Typography>
        </Typography>

        <Typography
          textAlign={"center"}
          variant="h3"
          fontWeight={"600"}
          fontSize={"3vw"}
        >
          Why Mud Wrestling?
        </Typography>
        <Box>
          <Box component="img" src="/images/girls.jpg" width={"100%"}></Box>
          <Typography
            padding={"1em"}
            gap={"1em"}
            variant="h5"
            fontSize={"1.5vw"}
          >
            Bringing Women mud wrestling into crypto is a creative concept that
            brings a unique blend of entertainment and blockchain technology.
            This idea leverages the excitement of mud wrestling and combines it
            with the transparency, security, and decentralized nature of
            cryptocurrency. By bringing this spectacle on-chain, it offers fans
            an innovative way to engage with the sport, bet on outcomes, and
            participate in the community.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Ads({ side }) {
  return (
    <Box
      component="a"
      href={
        side === "left"
          ? "https://www.twitch.tv/wmwrestling"
          : "https://kick.com/womenmudwrestling"
      }
      textDecoration="none"
      className="adName"
    >
      {side === "left" ? (
        <Box sx={ads}>
          {obj[0].map((item) => (
            <Ad key={item.id} name={item.name} src={item.src} />
          ))}
        </Box>
      ) : (
        <Box sx={ads} marginLeft={"auto"}>
          {obj[1].map((item) => (
            <Ad key={item.id} name={item.name} src={item.src} />
          ))}
        </Box>
      )}
    </Box>
  );
}

function Ad({ name, src }) {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        bgcolor={"#000080"}
        padding=".35em .65em"
      >
        <Box fontSize={"1vw"} component="p" color={"white"}>
          {name}
        </Box>
        <Box
          className="cursor"
          sx={adInput}
          component="img"
          src={"/images/inputs/exit.png"}
          alt="exit"
        />
      </Box>
      <Box
        className="cursor"
        component="img"
        src={src}
        alt="ad-img"
        width={"100%"}
      />
    </>
  );
}
