import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

export default function Paint() {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);

  const pixel = {
    fontFamily: "W95FA",
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineWidth = 5;
    setContext(ctx);
  }, []);

  const startDrawing = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    context.beginPath();
    context.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = e.nativeEvent;
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  const stopDrawing = () => {
    context.closePath();
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  return (
    <Box
      display={"flex"}
      position={"relative"}
      style={{
        backgroundColor: "white",
      }}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding="0.5em"
        bgcolor="#C0C0C0"
        position={"absolute"}
        width={"100%"}
        sx={pixel}
      >
        <button position={"absolute"} bottom={"0"} onClick={clearCanvas}>
          <Typography variant="p" sx={pixel} padding={".25em"} fontSize={"1vw"}>
            Clear
          </Typography>
        </button>
      </Box>
      <canvas
        ref={canvasRef}
        width={1000}
        height={800}
        style={{ border: "1px solid #000", cursor: "crosshair" }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
      ></canvas>
    </Box>
  );
}
