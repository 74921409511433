import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";
import "./desktop.css";
import Browser from "../Applications/Browser/Browser";
import RecycleBin from "../Applications/RecycleBin/RecycleBin";
import Paint from "../Applications/Paint/Paint";
import MediaPlayer from "../Applications/MediaPlayer/MediaPlayer";
import PhotoViewer from "../Applications/PhotoViewer/PhotoViewer";

let desktopObj = [
  {
    src: "/images/taskbar/icons/desktop/bin.png",
    srcTwo: "/images/taskbar/icons/taskbar/bin.png",
    name: "Recycle Bin",
    alt: "recycle",
  },
  {
    src: "/images/taskbar/icons/desktop/explorer.png",
    srcTwo: "/images/taskbar/icons/taskbar/explorer.png",
    name: "Internet Explorer",
    alt: "explorer",
  },
  {
    src: "/images/taskbar/icons/desktop/camera.png",
    srcTwo: "/images/taskbar/icons/desktop/camera.png",
    name: "Photo Viewer",
    alt: "photo viewer",
  },
  {
    src: "/images/taskbar/icons/desktop/video.png",
    srcTwo: "/images/taskbar/icons/desktop/video.png",
    name: "Media Player",
    alt: "media player",
  },
  {
    src: "/images/taskbar/icons/desktop/paint.png",
    srcTwo: "/images/taskbar/icons/desktop/paint.png",
    name: "Paint",
    alt: "paint",
  },
];

const browserInput = {
  width: "2vw",
};

const pixel = {
  fontFamily: "W95FA",
};

export default function Desktop() {
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const [activeTaskBarItems, setActiveTaskBarItems] = useState([]);
  const [openWindows, setOpenWindows] = useState({});
  const [zIndices, setZIndices] = useState({});
  const [nextZIndex, setNextZIndex] = useState(1);

  const addItemToTaskBar = (item) => {
    setActiveTaskBarItems((prevItems) => {
      if (!prevItems.some((i) => i.name === item.name)) {
        return [...prevItems, item];
      }
      return prevItems;
    });
  };

  const openComponent = (component) => {
    setOpenWindows((prevWindows) => ({
      ...prevWindows,
      [component]: true,
    }));
    bringToFront(component);
  };

  const toggleVisibility = (component) => {
    setOpenWindows((prevWindows) => ({
      ...prevWindows,
      [component]: !prevWindows[component],
    }));
    bringToFront(component);
  };

  const bringToFront = (component) => {
    setZIndices((prevZIndices) => ({
      ...prevZIndices,
      [component]: nextZIndex,
    }));
    setNextZIndex(nextZIndex + 1);
  };

  const minimizeWindow = (component) => {
    setOpenWindows((prevWindows) => ({
      ...prevWindows,
      [component]: false,
    }));
  };

  const closeWindow = (component) => {
    setOpenWindows((prevWindows) => {
      const newWindows = { ...prevWindows };
      delete newWindows[component];
      return newWindows;
    });
    setActiveTaskBarItems((prevItems) =>
      prevItems.filter((item) => item.alt !== component)
    );
  };

  if (!isLargeScreen) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        textAlign="center"
      >
        <Typography variant="h4" color="white">
          Only available on Desktop
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      position={"relative"}
      width={"100vw"}
      height={"100vh"}
      overflow={"hidden"}
    >
      <Box
        position={"absolute"}
        top={"0"}
        bgcolor={"#008080"}
        width={"100%"}
        height={"100%"}
      >
        <DesktopIcons
          addItemToTaskBar={addItemToTaskBar}
          openComponent={openComponent}
        />
      </Box>
      <Taskbar
        activeTaskBarItems={activeTaskBarItems}
        openWindows={openWindows}
        toggleVisibility={toggleVisibility}
      />
      {openWindows["recycle"] && (
        <DraggableComponent
          name="Recycle Bin"
          iconSrc="/images/taskbar/icons/desktop/bin.png"
          zIndex={zIndices["recycle"]}
          bringToFront={() => bringToFront("recycle")}
          minimize={() => minimizeWindow("recycle")}
          close={() => closeWindow("recycle")}
          width={"35vw"}
          height={"50vh"}
        >
          <RecycleBin />
        </DraggableComponent>
      )}
      {openWindows["explorer"] && (
        <DraggableComponent
          name="Internet Explorer"
          iconSrc="/images/taskbar/icons/desktop/explorer.png"
          zIndex={zIndices["explorer"]}
          bringToFront={() => bringToFront("explorer")}
          minimize={() => minimizeWindow("explorer")}
          close={() => closeWindow("explorer")}
          handleTextColor="#C0C0C0"
          width={"80vw"}
          height={"85vh"}
        >
          <Browser />
        </DraggableComponent>
      )}
      {openWindows["photo viewer"] && (
        <DraggableComponent
          name="Photo Viewer"
          iconSrc="/images/taskbar/icons/desktop/camera.png"
          zIndex={zIndices["photo viewer"]}
          bringToFront={() => bringToFront("photo viewer")}
          minimize={() => minimizeWindow("photo viewer")}
          close={() => closeWindow("photo viewer")}
          width={"70vw"}
          height={"80vh"}
        >
          <PhotoViewer />
        </DraggableComponent>
      )}
      {openWindows["media player"] && (
        <DraggableComponent
          name="Media Player"
          iconSrc="/images/taskbar/icons/desktop/video.png"
          zIndex={zIndices["media player"]}
          bringToFront={() => bringToFront("media player")}
          minimize={() => minimizeWindow("media player")}
          close={() => closeWindow("media player")}
          width={"66vw"}
          height={"80vh"}
        >
          <MediaPlayer />
        </DraggableComponent>
      )}
      {openWindows["paint"] && (
        <DraggableComponent
          name="Paint"
          iconSrc="/images/taskbar/icons/desktop/paint.png"
          zIndex={zIndices["paint"]}
          bringToFront={() => bringToFront("paint")}
          minimize={() => minimizeWindow("paint")}
          close={() => closeWindow("paint")}
          width={"50vw"}
          height={"75vh"}
        >
          <Paint />
        </DraggableComponent>
      )}
    </Box>
  );
}

function DesktopIcons({ addItemToTaskBar, openComponent }) {
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleClick = (index) => {
    setClickedIndex(index);
  };

  const handleDoubleClick = (item) => {
    addItemToTaskBar(item);
    openComponent(item.alt);
  };

  return (
    <Box
      width={"8vw"}
      display={"flex"}
      flexDirection={"column"}
      gap={"1vw"}
      paddingTop={"1vw"}
      paddingLeft={"1vw"}
    >
      {desktopObj.map((item, index) => (
        <DesktopIcon
          key={index}
          item={item}
          isClicked={index === clickedIndex}
          onClick={() => handleClick(index)}
          onDoubleClick={() => handleDoubleClick(item)}
        />
      ))}
    </Box>
  );
}

function DesktopIcon({ item, isClicked, onClick, onDoubleClick }) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      width={"5vw"}
      position={"relative"}
      gap={".25vw"}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      sx={{ cursor: "pointer" }}
    >
      <Box
        component={"img"}
        src={item.src}
        alt={item.alt}
        width={"3.5vw"}
      ></Box>
      <Typography
        variant={"p"}
        fontSize={"1vw"}
        textAlign={"center"}
        bgcolor={isClicked ? "blue" : ""}
        color={isClicked ? "white" : ""}
        border={isClicked ? "1px dashed grey" : ""}
        fontFamily={pixel}
      >
        {item.name}
      </Typography>
      <Box
        className={`desktop-icon ${isClicked ? "clicked" : ""}`}
        width={"100%"}
        height={"100%"}
        position={"absolute"}
        top={"0"}
        zIndex={"3"}
      ></Box>
    </Box>
  );
}

function Taskbar({ activeTaskBarItems, openWindows, toggleVisibility }) {
  return (
    <Box
      bgcolor={"#C0C0C0"}
      position={"absolute"}
      bottom={"0"}
      width={"100vw"}
      height={"5vh"}
      boxSizing={"border-box"}
      borderTop={"1px solid white"}
      borderRight={"1px solid black"}
      borderLeft={"1px solid white"}
      display={"flex"}
      padding={".15em 0"}
    >
      <Box
        component={"img"}
        src="/images/taskbar/start.png"
        height={"100%"}
        marginRight={".5vw"}
      />
      <TaskbarItems
        activeTaskBarItems={activeTaskBarItems}
        openWindows={openWindows}
        toggleVisibility={toggleVisibility}
      />
      <Time />
    </Box>
  );
}

function TaskbarItems({ activeTaskBarItems, openWindows, toggleVisibility }) {
  return (
    <Box width={"100%"} display={"flex"} gap={".5vw"}>
      {activeTaskBarItems.map((item, index) => (
        <TaskbarItem
          key={index}
          src={item.srcTwo}
          alt={item.alt}
          name={item.name}
          isOpen={openWindows[item.alt]}
          onClick={() => toggleVisibility(item.alt)}
        />
      ))}
    </Box>
  );
}

function TaskbarItem({ src, alt, name, isOpen, onClick }) {
  return (
    <Box
      className={`shadow ${isOpen ? "open" : ""}`}
      borderTop={isOpen ? "2px solid #303030" : "1px solid #CCC9C2"}
      borderLeft={isOpen ? "1px solid #5e5e5e" : "1px solid #CCC9C2"}
      borderRight={isOpen ? "1px solid #5e5e5e" : "1px solid #8A8581"}
      borderBottom={isOpen ? "1px solid white" : "1px solid #8A8581"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      padding={"0 .5em"}
      bgcolor={isOpen ? "#919191" : "#C0C0C0"}
      gap={".5em"}
      justifyContent={"flex-start"}
      width={"12vw"}
      onClick={onClick}
    >
      <Box component={"img"} src={src} alt={alt} width={"1.5vw"} />
      <Typography
        fontFamily={pixel}
        variant={"body2"}
        fontSize={"1vw"}
        fontWeight={"600"}
      >
        {name}
      </Typography>
    </Box>
  );
}

function Time() {
  return (
    <Box
      borderTop={"1px solid #606060"}
      borderLeft={"1px solid #606060"}
      borderRight={"1px solid white"}
      borderBottom={"1px solid white"}
      width={"5vw"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography fontFamily={pixel} fontWeight={"600"}>
        $WMW
      </Typography>
    </Box>
  );
}

// Draggable Component Wrapper
function DraggableComponent({
  children,
  name,
  iconSrc,
  zIndex,
  bringToFront,
  minimize,
  close,
  handleTextColor,
  width,
  height,
}) {
  const handleStyle = {
    backgroundColor: "#0000a8",
    position: "sticky",
    top: 0,
    zIndex: 1,
    color: "white",
  };

  return (
    <Draggable handle=".handle">
      <Box
        position={"absolute"}
        top={`calc(50vh - ${parseFloat(height) / 2}vh)`}
        left={`calc(50vw - ${parseFloat(width) / 2}vw)`}
        width={width}
        height={height}
        className="draggable-window"
        zIndex={zIndex}
        boxShadow={"0 0 10px rgba(0,0,0,0.5)"}
        overflow={name === "Internet Explorer" ? "scroll" : "hidden"}
        onClick={bringToFront}
      >
        <Box
          className="handle"
          sx={handleStyle}
          padding={".5em"}
          cursor={"move"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display="flex" alignItems="center" gap=".5em">
            <Box component="img" src={iconSrc} width="1.5vw" />
            <Typography fontFamily={pixel} variant={"h5"} fontWeight={"600"}>
              {name}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={browserInput}
              component="img"
              src="/images/inputs/minimize.jpg"
              style={{ margin: "0 .25em" }}
              onClick={minimize}
            />
            <Box
              sx={browserInput}
              component="img"
              src="/images/inputs/exit.png"
              style={{ margin: "0 .25em" }}
              onClick={close}
            />
          </Box>
        </Box>
        {children}
      </Box>
    </Draggable>
  );
}
